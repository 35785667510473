@font-face {
  font-family: "Segoe UI";
  src: url("./fonts/623c7118249e082fe87a78e08506cb4b.eot");
  src: url("./fonts/623c7118249e082fe87a78e08506cb4b\ \(1\).eot")
      format("embedded-opentype"),
    url("./fonts/623c7118249e082fe87a78e08506cb4b.woff2") format("woff2"),
    url("./fonts/623c7118249e082fe87a78e08506cb4b.woff") format("woff"),
    url("./fonts/623c7118249e082fe87a78e08506cb4b.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI Light";
  src: url("./fonts/segoeuil.ttf");
}

#exuus-site {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  font-size: 20px;
  font-family: "Segoe UI";
  color: #4f4f4f;
}

#header,
#footer {
  display: flex;
  height: 80px;
  padding: 0px 64px;
  flex-direction: row;
}

#header {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
}

#header.scrolled {
  background-color: #f7f7f7;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

#logo {
  display: block;
  height: 24px;
  margin: 28px 0px;
}

#logo img {
  display: block;
  height: 24px;
}

#menu {
  display: block;
  height: 80px;
  line-height: 80px;
}

#menu *:not(:first-child) {
  padding-left: 32px;
}

#menu a {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
}

#menu a img {
  margin-top: -4px;
  height: 24px;
  width: 24px;
}

#header #menu span {
  font-weight: 600;
}

#body {
  display: flex;
  min-height: calc(100% - 160px);
  padding: 0px 64px 0px 168px;
  flex-direction: row;
}

h1 {
  font-size: 38px;
}

#body .left,
#body .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

#body .left {
  align-items: flex-start;
  padding-right: 40px;
}

#body .left p {
  font-family: "Segoe UI Light" !important;
  font-size: 22px;
  font-weight: 100;
  margin-bottom: 1rem;
}

#body .left .more {
  color: #f46a4a;
  cursor: pointer;
}

#body .left .welcome {
  padding-bottom: 11px;
}

#body .left .signature {
  padding-top: 12px;
}

#body .right {
  align-items: flex-end;
  padding-left: 116px;
}

#body .right img.illustration-mobile {
  display: none;
}

#body .right img {
  max-width: 100%;
  min-width: 100%;
}

#body .right .product-link {
  text-decoration: none;
  color: #1c9bb2;
  font-size: 16px;
  font-weight: 700;
  font-family: "Segoe UI";
  padding: 4px 21px;
  border: 0.7px solid rgba(28, 155, 178, 0.4);
  border-radius: 20px;
  margin-top: 2rem;
  display: flex;
}

#body .right .product-link .save-logo {
  height: 13px;
  max-width: unset;
  min-width: auto;
  margin-left: 7px;
  margin-top: 6px;
}

#footer div:first-child a {
  height: 20px;
}

#footer div:first-child a img {
  height: 20px;
  width: 20px;
}

#footer div a:nth-child(4) img {
  margin-top: 1px;
}

#footer .copy {
  font-size: 12px;
}

#footer .copy a {
  width: 48px;
  height: 32px;
  padding-left: 16px;
}

#footer .copy a img {
  width: 26px;
  height: 26px;
}

@media only screen and (max-width: 1366px) and (min-width: 1291px) {
  #body .right {
    padding-left: 180px;
  }

  #body .left p {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 1290px) and (min-width: 1230px) {
  #body {
    margin-top: -20px;
  }

  #body .left p {
    font-size: 18px;
    margin-bottom: 0.5rem;
  }

  #footer {
    margin-top: 12px;
  }

  #body .left .welcome {
    padding-bottom: 5px;
  }

  #body .left .signature {
    padding-top: 8px;
  }
}

@media only screen and (max-width: 1000px) and (min-width: 801px) {
  #body {
    padding: 0px 64px;
  }

  #body .left p {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 800px) {
  #exuus-site {
    font-size: 14px;
  }
  #header,
  #footer {
    height: 56px;
    padding: 0px 16px;
  }

  #footer div:first-child a img {
    height: 16px;
  }

  #header {
    background-color: #f7f7f7;
  }

  #logo {
    height: 24px;
    margin: 16px 0px;
  }

  #logo img {
    height: 16px;
  }
  #logo .sub-logo {
    margin-bottom: 1rem;
  }

  #menu {
    height: 56px;
    line-height: 56px;
  }

  #menu *:not(:first-child) {
    padding-left: 16px;
  }

  #menu a {
    height: 16px;
    line-height: 16px;
  }

  #menu a img {
    margin-top: -4px;
    height: 16px;
    width: 16px;
  }

  #menu.products a {
    position: fixed;
    display: block;
    width: 40px;
    height: 40px;
    z-index: 999;
    right: -48px;
    padding: 0px 0px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }

  #menu.products a:nth-child(2) {
    top: 72px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }

  #menu.products a:last-child {
    top: 128px;
    -webkit-transition: all 0.75s;
    -moz-transition: all 0.75s;
    transition: all 0.75s;
  }

  #menu.products.show a:nth-child(2) {
    right: 16px;
  }

  #menu.products.show a:last-child {
    right: 16px;
  }

  #menu.products a img {
    width: 40px;
    height: 40px;
    margin: 0px 0px;
  }

  #body {
    flex-direction: column-reverse;
    padding: 10px 16px;
    min-height: calc(100% - 112px);
  }

  #body .left {
    align-items: flex-start;
    padding-right: 0px;
    flex: 1;
  }

  #body .right {
    align-items: flex-start;
    padding-left: 0px;
    /* margin: 0px -16px; */
    margin-bottom: 30px;
    flex: 0;
  }

  #body .right img.illustration-mobile {
    display: block;
  }

  #body .right img:first-child {
    display: none;
  }

  h1 {
    font-size: 24px;
  }

  .welcome {
    padding-bottom: 3px !important;
  }

  .signature {
    padding-top: 0px !important;
  }

  .signature img {
    height: 16px;
  }
}
